.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.save-button-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: 0 12px 12px 0;
}

.add-bank-account-button {
  margin: 16px 0 0 12px !important
}

.remove-bank-account-button {
  width: 24px;
  max-width: 24px;
  min-width: 24px;
  margin: 0 0 0 12px !important;
  padding: 0 8px 0 0 !important;
}

.remove-bank-account-button:hover {
  color: red;
}

.text-field {
  margin: 8px !important;
}

.tag-row {
  display: flex;
  min-height:48px !important;
}

.tag-label {
  min-width: 150px !important;
  display: inline-block;
  margin-left: 64px;
}

.tag-header {
  font-weight: 700 !important;
  display: inline-block;
  min-width: 150px !important;
  text-align: left !important;
}

.tag-header:first-child {
  margin-left: 64px;
}

.tags-container {
  padding: 16px !important;
}

.tags-divider {
  margin-bottom: 68px !important;
}

.tag-text-field {
  margin-top: -3px !important
}

.colors-container {
  padding: 16px;
}

.tag-color {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 4px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
